import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { RestService } from "../rest.service";
import { DataService } from "../data.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { TermsOfUseComponent } from "../terms-of-use/terms-of-use.component";
import { ToastrService } from "ngx-toastr";
import { commonConstants } from "../constants/constants";
import { CookieService } from "ngx-cookie-service";
import { MsalAuthService } from "../../msal-auth.service";

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit, AfterContentChecked {
  constructor(
    private cdRef: ChangeDetectorRef,
    private restService: RestService,
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog,
    private toastr: ToastrService,
    public authService: MsalAuthService,
    private cookie: CookieService
  ) {}

  onboardUser = false;
  manageUser = false;
  manageProject = false;
  manageApplication = false;
  manageRole = false;
  onboardingHistory = false;
  addUser = false;
  notificationCount: number;
  isTermsOfUse = false;
  dashboard = false;
  loginMsg = commonConstants.loginErrorMsg;

  ngOnInit() {
    const storedRole = this.cookie.get("userRole");

    this.restService.getUserDetails().subscribe(
      (res) => {
        if (
          res.status === "Requested" ||
          res.status === "Not Found" ||
          res.status === "INACTIVE"
        ) {
          this.addUser = true;
        } else if (res.status === "Approved") {
          this.manageRole = res.manageRole;
          this.onboardingHistory = res.onboardingHistory;
          this.addUser = res.addUser;
          this.onboardUser = res.onboardUser;
          this.manageUser = res.manageUser;
          this.manageProject = res.manageProject;
          this.manageApplication = res.manageApplication;
          this.dashboard = true;
        }
        if (this.onboardUser) {
          this.restService.getApprovalRequests().subscribe((data: any[]) => {
            this.notificationCount = data.length;
          });
        }
        this.dataService.requestCount.subscribe(
          (requestCount) => (this.notificationCount = requestCount)
        );
      },
      (error) => {
        if (typeof error.message !== "undefined") {
          this.toastr.error(error.message);
        } else if (typeof error.error !== "undefined") {
          if (error.error === "login_required") {
            this.toastr.error(this.loginMsg);
            this.logout();
          } else {
            this.toastr.error(error.error);
          }
        }
      }
    );
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }
  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout();
    this.cookie.deleteAll();
    console.log("Loggin out..");
    this.router.navigate([""]);
  }
  openDialog() {
    const dialogRef = this.dialog.open(TermsOfUseComponent, {
      width: "50%",
      height: "40%",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
