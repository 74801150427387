import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  NgZone,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  FormBuilder,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatTableDataSource,
} from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { RestService } from "../common/rest.service";
import { RequestConfirmationDialogComponent } from "../request-confirmation-dialog/request-confirmation-dialog.component";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MsalAuthService } from "../msal-auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-admin-approval",
  templateUrl: "./admin-approval.component.html",
  styleUrls: ["./admin-approval.component.scss"],
})
export class AdminApprovalComponent implements OnInit {
  approvalForm: FormGroup;
  applicationArray: any = new Array();
  projects: any;
  roles: any;
  code: any;
  statusValues: any;
  showStatus = false;
  requestedApplicationArray = [];
  isApplicationCheckedArray = [];
  formArrayApplicationValues = [];
  unselectedApplicationArray = new Array();
  actionSelectedApplicationArray = new Array();
  clicked = false;
  readonly = "true";
  superAdminOption = false;
  dialogResult = false;
  checkRemarksEmpty = false;
  editMode: boolean = false;
  isEditMode: boolean = false;

  editProjectMode = false;
  hasConfirmedEditRole = false;
  hasConfirmedEditProject = false;
  hasConfirmedGeneralEdit = false;

  rolesOptions: string[] = ["Super Admin", "Site Admin", "Site User"];

  projectInputControl = new FormControl();
  projectArray: any[];
  otherUsers: boolean;
  projectVerified: boolean;
  apps: any[];
  userRole: string;
  projectIdOrNameObj: string;

  unselectedApps: any[] = [];
  filteredOptions: Observable<string[]>;

  currentUser: any;
  userList: any[] = [];
  dataSource: MatTableDataSource<any>;

  userLoggedIn = false;
  userName: string;
  isLoading = false;
  loading: boolean = true;
  submitted = false;
  projectNameAndCode =
    this.data.userData.project.detail.name +
    " - " +
    this.data.userData.project.detail.code;
  isSelected = true;
  isButtonDisabled: boolean = false;
  oldProjectName: string | null = null;
  isProjectSelected: boolean = false;
  oldRoleValue: string | null = null;
  projectInput: string | null = null;
  applicationsFetched: boolean = false;
  shouldHideApplications = false;
  shouldShowFetchButton = false;
  checkedApps: string[] = [];

  selectedProject: string;
  isTouched = false;
  editButtonEnabled = true;
  statusChanged: boolean = false;
  buttonLabel: string = "Update";
  isRejectClicked: boolean = false;
  isFormChanged: boolean = false;
  isSuperAdminUser: boolean = false;

  constructor(
    private restService: RestService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminApprovalComponent>,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    private restApi: RestService,
    private snackBar: MatSnackBar,
    private msalAuth: MsalAuthService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private cookie: CookieService
  ) {
    if (data.buttonLabel) {
      this.buttonLabel = data.buttonLabel;
    }

    if (typeof data.isEditMode === "boolean") {
      this.isEditMode = data.isEditMode;
    }
    if (typeof data.isFormChanged === "boolean") {
      this.isFormChanged = data.isFormChanged;
    }
    dialogRef.disableClose = true;
    this.projectInputControl = new FormControl("");
    this.approvalForm = this.fb.group({
      projectName: this.projectInputControl,
    });
  }

  ngOnInit() {
    // this.isSuperAdmin();
    this.isSuperAdmin().subscribe((isSuperAdmin) => {
      this.isSuperAdminUser = isSuperAdmin;
      console.log("Super Admin status:", this.isSuperAdminUser);
    });

    this.userLoggedIn = this.msalAuth.loggedIn;
    this.userName = this.msalAuth.loggedInUser;
    if (sessionStorage.getItem("userName")) {
      this.userLoggedIn = true;
      this.userName = sessionStorage.getItem("userName");
    }

    this.initializeForm();

    this.filteredOptions = this.projectInputControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.approvalForm.get("status").valueChanges.subscribe((newStatus) => {
      if (newStatus) {
        this.statusChanged = true;

        this.cdr.detectChanges();
      }
    });
    this.approvalForm.get("role").valueChanges.subscribe((newRole) => {
      this.onRoleChange(newRole);
      this.cdr.detectChanges();
    });
    this.approvalForm.valueChanges.subscribe(() => {
      // Check if any field in the form is dirty or touched
      this.isFormChanged = this.approvalForm.dirty || this.approvalForm.touched;
    });
    this.isApplicationCheckedArray.forEach((app) => {
      const control = this.fb.control(false);
      (this.approvalForm.get("applicationName") as FormArray).push(control);
    });
  }

  initializeForm() {
    if (this.data.userData) {
      this.projectInputControl = new FormControl();
      this.otherUsers = false;

      this.getApplications(this.data.userData.project.detail.name);
      this.getRequestedApplication(
        this.data.userData.project.detail.applicationName
      );
      this.getApplicationCode(this.data.userData.project.detail.code);
      this.getRoles();
      this.getStatus();
      this.checkForStatus(this.data.userData);
      this.checkForRemarks(this.data.userData.project.detail.remarks);

      this.approvalForm = this.fb.group({
        name: this.data.userData.lastName + ", " + this.data.userData.firstName,
        emailId: this.data.userData.emailId,
        gid: this.data.userData.gid,
        role: [this.data.userData.project.role, Validators.required],
        projectName: [this.projectNameAndCode, Validators.required],
        projectCode: [
          this.data.userData.project.detail.code,
          Validators.required,
        ],
        status: "ACTIVE",
        department: "Civil Construction",
        approved: "true",
        applicationUnselected: [],
        actionSelected: [],
        remarks: [null, Validators.required],
        applicationName: this.fb.array([]),
      });

      this.approvalForm.setControl(
        "applicationName",
        this.fb.array(this.requestedApplicationArray)
      );

      this.approvalForm.get("role").valueChanges.subscribe((value) => {
        this.checkForSuperAdmin(value);
      });

      this.checkForSuperAdmin(this.approvalForm.value.role);
      this.getAllProjectDetails();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    if (!this.projectArray) {
      return [];
    }
    return this.projectArray.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.code.toLowerCase().includes(filterValue)
    );
  }

  getUncheckedApps(appName: string, isChecked: boolean) {
    const index = this.apps.findIndex((app) => app.name === appName);
    if (index >= 0) {
      this.apps[index].checked = isChecked;
    }

    if (!isChecked && !this.unselectedApps.includes(appName)) {
      this.unselectedApps.push(appName);
    } else if (isChecked && this.unselectedApps.includes(appName)) {
      const unselectedIndex = this.unselectedApps.indexOf(appName);
      this.unselectedApps.splice(unselectedIndex, 1);
    }

    if (isChecked && !this.checkedApps.includes(appName)) {
      this.checkedApps.push(appName);
    } else if (!isChecked && this.checkedApps.includes(appName)) {
      const checkedIndex = this.checkedApps.indexOf(appName);
      this.checkedApps.splice(checkedIndex, 1);
    }

    this.approvalForm.setControl(
      "applicationName",
      this.fb.array(this.checkedApps.map((name) => this.fb.control(name)))
    );
  }

  appName(appName: any): import("@angular/forms").AbstractControl {
    throw new Error("Method not implemented.");
  }

  fetchApplications() {
    this.applicationsFetched = true;
    this.shouldHideApplications = false;

    const projectInput = this.approvalForm.get("projectName").value;

    const projectName = projectInput ? projectInput.split("-")[1].trim() : null;

    this.restService.getApplications().subscribe(
      (response) => {
        this.apps = response.map((app) => ({ ...app, checked: true }));

        this.approvalForm.patchValue({
          projectName: projectInput,
        });

        this.cdr.detectChanges();
      },
      (error) => {
        console.error("Error fetching applications", error);
      }
    );
  }

  checkForRemarks(data: any) {
    if (!data) {
      this.checkRemarksEmpty = true;
    }
  }
  checkForStatus(data: any) {
    if (data.status) {
      this.showStatus = true;
    }
  }

  isSuperAdmin(): Observable<boolean> {
    return this.restService.getCurrentUserRole().pipe(
      map((data: string[]) => {
        // console.log("Roles from API:", data);
        return data.length === 1 && data[0] === "Super Admin";
      })
    );
  }

  onEdit() {
    this.isEditMode = true;
    this.approvalForm.get("projectName").enable();
    this.oldProjectName = this.approvalForm.get("projectName").value;
  }

  onRoleChange(selectedRole: string): void {
    this.editProjectMode = true;

    this.editProjectMode = true;

    if (selectedRole === "Super Admin") {
      this.shouldShowFetchButton = false;
      this.shouldHideApplications = true;
    } else {
      this.shouldHideApplications = false;
    }

    this.projectVerified = !!selectedRole;

    if (this.approvalForm.dirty) {
      const currentProject = this.approvalForm.get("projectName").value;
      const currentApplications =
        this.approvalForm.get("applicationName").value;

      if (selectedRole !== "Super Admin") {
        this.approvalForm.patchValue({
          projectName: currentProject,
          applicationName: currentApplications,
        });
      }
    }

    this.projectVerified = !!selectedRole;
  }

  toggleEditProjectMode() {
    this.editProjectMode = !this.editProjectMode;

    if (this.editProjectMode) {
      this.oldProjectName = this.approvalForm.get("projectName").value;

      this.shouldHideApplications = true;
      this.applicationsFetched = false;
      this.shouldShowFetchButton = true;

      this.approvalForm.get("projectName").setValue(this.oldProjectName);
    } else {
      if (this.approvalForm.get("projectName").value === this.oldProjectName) {
        this.approvalForm.get("projectName").setValue(this.oldProjectName);
      }

      this.projectVerified = false;
      this.shouldHideApplications = false;
    }
  }

  // toggleEditMode(event: Event) {
  //   this.editButtonEnabled = false;
  //   this.isEditMode = true;
  //   this.editProjectMode = true;
  //   // this.oldProjectName = this.approvalForm.get("projectName").value;

  //   const storedRole = this.cookie.get("userRole"); // Get the current role value

  //   if (storedRole === "Site Admin") {
  //     this.editMode = !this.editMode;
  //     // Enable only the 'status' field for editing
  //     this.approvalForm.get("status").enable(); // Enable status field for editing
  //     this.approvalForm.get("projectName").disable(); // Disable other fields
  //     this.approvalForm.get("role").disable();
  //     this.approvalForm.get("applicationName").disable();
  //   } else {
  //     // If not Site Admin, allow normal editing flow
  //     if (!this.hasConfirmedEditRole) {
  //       this.openConfirmationDialog("Do you wish to edit the details?", () => {
  //         this.editMode = !this.editMode;
  //         if (this.editMode) {
  //           this.shouldHideApplications = false;
  //           this.oldRoleValue = this.approvalForm.get("role").value;
  //         }
  //         this.hasConfirmedEditRole = true;
  //       });
  //     }
  //   }
  // }
  toggleEditMode(event: Event) {
    this.editButtonEnabled = false;
    this.isEditMode = true;
    this.editProjectMode = true;
    this.oldProjectName = this.approvalForm.get("projectName").value;

    if (!this.hasConfirmedEditRole) {
      this.openConfirmationDialog("Do you wish to edit the details?", () => {
        this.editMode = !this.editMode;
        if (this.editMode) {
          this.shouldHideApplications = false;
          this.oldRoleValue = this.approvalForm.get("role").value;
        }
        this.hasConfirmedEditRole = true;
      });
    }
  }

  onProjectChange(selectedProject: string) {
    this.shouldHideApplications = true;
    this.projectInput = selectedProject;

    const applicationNameArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;
    applicationNameArray.clear();

    this.applicationsFetched = false;
    this.projectVerified = !!selectedProject;
    this.approvalForm.get("projectName").setValue(selectedProject);

    this.shouldShowFetchButton = true;
    if (selectedProject === "ALL - PALL") {
      this.selectedProject = null;
      this.approvalForm.get("projectName").setValue(null);
      this.shouldShowFetchButton = true;
    } else {
      this.selectedProject = selectedProject;
    }
  }
  isAllApplicationsSelected(): boolean {
    return this.approvalForm.get("applicationName").value.includes("ALL");
  }

  openConfirmationDialog(message: string, onConfirm: () => void) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "38%",
      data: { message: message },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        onConfirm();
      }
    });
  }

  verify(projectInput: string): void {
    this.shouldHideApplications = true;
    this.isLoading = true;
    this.shouldShowFetchButton = false;
    this.applicationsFetched = false;
    this.projectVerified = false;

    if (!projectInput && this.oldProjectName) {
      projectInput = this.oldProjectName;
    }

    if (!projectInput || !projectInput.includes("-")) {
      this.toastr.warning("Project input is invalid or missing.");
      this.isLoading = false;
      return;
    }

    const [projectName, projectCode] = projectInput
      .split("-")
      .map((part) => part.trim());

    if (!projectName || !projectCode) {
      this.toastr.warning("Project name or code is missing.");
      this.isLoading = false;
      return;
    }

    this.restService
      .getProject(projectName)
      .pipe(
        switchMap((projectResult) => {
          if (!projectResult || !projectResult.apps) {
            throw new Error("Project applications not found");
          }

          const hasLOP = projectResult.apps.some(
            (app) => app.name.toUpperCase() === "LOP"
          );

          if (hasLOP) {
            return this.restService.getUserRoleInIcmmsLop(projectCode).pipe(
              map((icmmsResult) => ({
                projectApps: projectResult.apps,
                icmmsRole: icmmsResult.response,
              }))
            );
          } else {
            return of({ projectApps: projectResult.apps, icmmsRole: null });
          }
        })
      )
      .subscribe(
        ({ projectApps, icmmsRole }) => {
          if (icmmsRole === "CONTRACTOR") {
            this.apps = projectApps.filter(
              (app) => app.name.toUpperCase() === "LOP"
            );
            this.toastr.info("LOP contractor can access only LOP App", "", {
              timeOut: 5000,
            });
          } else if (icmmsRole === "NOT FOUND" || icmmsRole === "ERROR") {
            this.apps = projectApps.map((app) => ({
              ...app,
              disabled: app.name.toUpperCase() === "LOP",
            }));
            if (icmmsRole === "ERROR") {
              this.toastr.warning(
                "Access to LOP app is restricted due to an error in the ICMMS system",
                "",
                { timeOut: 5000 }
              );
            }
          } else {
            this.apps = projectApps;
          }

          this.projectVerified = true;
          this.applicationsFetched = true;
          this.shouldHideApplications = false;
        },
        (error) => {
          this.toastr.error(
            error.message || "Failed to fetch applications",
            "",
            { timeOut: 5000 }
          );
          this.projectVerified = false;
          this.applicationsFetched = false;
          this.shouldHideApplications = true;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  checkForSuperAdmin(roleDesired: any) {
    if (roleDesired === "Super Admin") {
      this.superAdminOption = true;
      this.disableProjectControls(true);
    } else if (roleDesired === "Site Admin" || roleDesired === "Site User") {
      this.superAdminOption = false;
      this.disableProjectControls(false, true);
    } else {
      this.superAdminOption = false;
      this.disableProjectControls(false, false);
    }
  }

  disableProjectControls(
    disable: boolean,
    disableProjectCode: boolean = false
  ) {
    if (disable) {
      this.approvalForm.get("projectName").disable();
      this.approvalForm.get("projectCode").disable();
      (this.approvalForm.get("applicationName") as FormArray).disable();
    } else {
      this.approvalForm.get("projectName").enable();
      if (disableProjectCode) {
        this.approvalForm.get("projectCode").disable();
      } else {
        this.approvalForm.get("projectCode").enable();
      }
      (this.approvalForm.get("applicationName") as FormArray).enable();
    }
  }

  getRequestedApplication(data) {
    this.requestedApplicationArray = data;
  }

  checkApplicationValues(applicationArray, formArray) {
    for (const form of formArray) {
      this.formArrayApplicationValues.push(form);
    }

    for (const app of applicationArray) {
      if (this.formArrayApplicationValues.includes(app)) {
        this.isApplicationCheckedArray.push({
          applicationName: app,
          checkboxValue: true,
        });
      } else {
        this.isApplicationCheckedArray.push({
          applicationName: app,
          checkboxValue: false,
        });
      }
    }
  }

  getStatus() {
    this.statusValues = ["ACTIVE", "INACTIVE"];
  }
  getApplications(projectName: any) {
    this.restService.getProjectUserApps(projectName).subscribe((result) => {
      this.applicationArray = result;

      this.checkApplicationValues(
        this.applicationArray,
        this.requestedApplicationArray
      );
    });
  }
  getAllProjectDetails() {
    this.restService.getAllProjectDetails().subscribe(
      (res) => {
        this.projectArray = res.filter(
          (p) => p.name !== "ALL" && p.status == "ACTIVE"
        );
      },
      (error) => {
        console.error("Error fetching project details:", error);
      }
    );
  }

  getApplicationCode(projectCode: any) {
    this.restService.getUserRoleInIcmmsLop(projectCode).subscribe((result) => {
      this.code = result;
    });
  }

  getRoles() {
    this.restService.getRoles().subscribe((d) => {
      this.roles = d;
    });
  }

  onChangeEventFunc(applicationName: string, isChecked: boolean) {
    if (!applicationName) {
      console.warn("Application name is null or undefined.");
      return;
    }

    const appIndex = this.isApplicationCheckedArray.findIndex(
      (app) => app.applicationName === applicationName
    );

    if (appIndex >= 0) {
      this.isApplicationCheckedArray[appIndex].checkboxValue = isChecked;
      this.checkClicked();

      const applicationArray = this.approvalForm.get(
        "applicationName"
      ) as FormArray;
      if (applicationArray && applicationArray.controls[appIndex]) {
        applicationArray.controls[appIndex].markAsTouched();
      }

      this.applicationArray = this.applicationArray || [];

      if (isChecked) {
        if (!this.applicationArray.includes(applicationName)) {
          this.applicationArray.push(applicationName);
        }

        this.isApplicationCheckedArray[appIndex].checkboxValue = true;

        if (!this.actionSelectedApplicationArray.includes(applicationName)) {
          this.actionSelectedApplicationArray.push(applicationName);
        }

        const unselectedIndex =
          this.unselectedApplicationArray.indexOf(applicationName);
        if (unselectedIndex >= 0) {
          this.unselectedApplicationArray.splice(unselectedIndex, 1);
        }
      } else {
        if (!this.unselectedApplicationArray.includes(applicationName)) {
          this.unselectedApplicationArray.push(applicationName);
        }

        const appIndexInArray = this.applicationArray.indexOf(applicationName);
        if (appIndexInArray >= 0) {
          this.applicationArray.splice(appIndexInArray, 1);
        }

        this.isApplicationCheckedArray[appIndex].checkboxValue = false;

        const actionIndex =
          this.actionSelectedApplicationArray.indexOf(applicationName);
        if (actionIndex >= 0) {
          this.actionSelectedApplicationArray.splice(actionIndex, 1);
        }
      }

      this.isFormChanged = true;
      console.log("Unselected Applications: ", this.unselectedApplicationArray);
      console.log(
        "Action Selected Applications: ",
        this.actionSelectedApplicationArray
      );
    } else {
      console.warn(`Application with name ${applicationName} not found.`);
    }
  }

  checkClicked() {
    this.clicked = true;
  }

  openDialog(title: string, message: string): void {
    const dialogConfirmRef = this.confirmDialog.open(
      RequestConfirmationDialogComponent,
      {
        width: "50%",
        data: {
          title,
          message,
        },
      }
    );

    dialogConfirmRef.afterClosed().subscribe((result) => {
      this.dialogResult = result;
      if (this.dialogResult) {
        this.request();
        this.dialogResult = false;
      }
    });
  }

  validateForm(): boolean {
    this.submitted = true;

    if (this.approvalForm.dirty && this.approvalForm.get("role").dirty) {
      this.applicationsFetched = true;
    }

    const applicationArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;

    if (!applicationArray) {
      console.error("applicationName FormArray is not defined.");
      return false; // Prevent further execution
    }

    const anyApplicationTouched = applicationArray.controls.some(
      (control) => control.touched || control.dirty
    );

    if (
      !this.applicationsFetched &&
      this.approvalForm.value.role !== "Super Admin" &&
      !anyApplicationTouched
    ) {
      this.toastr.warning("Please fetch applications before submitting.");
      return false;
    }

    if (applicationArray.length === 0) {
      this.toastr.warning("At least one application must be selected.");
      return false;
    }

    return true;
  }

  onAccept() {
    if (this.editMode) {
      this.applicationsFetched =
        this.approvalForm.value.role === "Super Admin"
          ? true
          : this.applicationsFetched;

      if (this.statusChanged) {
        this.handleStatusChange();
        return;
      }

      if (!this.validateForm()) {
        return;
      }

      this.approvalForm.get("remarks").clearValidators();
      this.approvalForm.get("remarks").updateValueAndValidity();

      let message = "Do you want to make the user inactive?";

      const currentRole = this.approvalForm.get("role").value;

      // Check if the role is being changed from Super Admin to any other role
      if (
        this.data.userData.project.role === "Super Admin" &&
        currentRole !== "Super Admin"
      ) {
        message =
          "User is currently Super Admin. Do you wish to revoke this access and update?";
        this.openDialog("Confirmation", message);
        console.log("yes");
        return;
      }

      if (this.approvalForm.value.role === "Super Admin") {
        this.approvalForm.patchValue({
          projectName: "ALL",
          applicationName: ["ALL"],
        });
        message =
          "Super Admin permissions will apply to all projects and applications. Do you wish to continue?";
        this.openDialog("Confirmation", message);
      } else if (this.approvalForm.value.status === "ACTIVE") {
        if (this.applicationArray.length === 0) {
          message = "Application is not selected. Do you wish to continue?";
          this.openDialog("Confirmation", message);
        } else {
          this.request();
        }
      } else {
        let projectInput = this.approvalForm.get("projectName").value;
        let projectName = projectInput
          ? projectInput.split("-")[0].trim()
          : null;

        if (!projectName && this.oldProjectName) {
          projectName = this.oldProjectName;
        }
        const updatedRole = this.approvalForm.get("role").value;
        this.approvalForm.get("role").setValue(updatedRole);

        let applicationNameValue =
          this.approvalForm.get("applicationName").value;
        if (!Array.isArray(applicationNameValue)) {
          applicationNameValue = applicationNameValue
            ? [applicationNameValue]
            : [];
        }

        this.approvalForm.patchValue({
          projectName: projectName,
          applicationName: applicationNameValue,
        });

        message =
          "Inactivating will revoke " +
          this.approvalForm.value.name +
          "'s access. Do you wish to continue?";
        this.openDialog("Confirmation", message);
      }
    } else {
      this.applicationsFetched = true;
      this.shouldHideApplications = false;
      this.isEditMode = true;

      this.approvalForm.get("remarks").clearValidators();
      this.approvalForm.get("remarks").updateValueAndValidity();
      let message = "Do you want to make the user inactive?";

      if (this.approvalForm.value.status === "ACTIVE") {
        if (this.applicationArray.length === 0) {
          message = "Application is not selected. Do you wish to continue?";
          this.openDialog("Confirmation", message);
        } else {
          this.requestedit();
        }
      } else {
        message =
          "Inactivating will revoke " +
          this.approvalForm.value.name +
          "'s access. Do you wish to continue?";
        this.openDialog("Confirmation", message);
      }
      this.approvalForm.markAsPristine(); // Mark the form as clean
      this.isFormChanged = false;
    }
  }

  requestedit() {
    this.isEditMode = true;

    if (this.clicked) {
      const applicationFormArray = this.applicationArray.map((app) =>
        this.fb.control(app)
      );
      this.approvalForm.setControl(
        "applicationName",
        this.fb.array(applicationFormArray)
      );

      const unselectedFormArray = this.unselectedApplicationArray.map((app) =>
        this.fb.control(app)
      );
      this.approvalForm.setControl(
        "applicationUnselected",
        this.fb.array(unselectedFormArray)
      );

      const actionSelectedFormArray = this.actionSelectedApplicationArray.map(
        (app) => this.fb.control(app)
      );
      this.approvalForm.setControl(
        "actionSelected",
        this.fb.array(actionSelectedFormArray)
      );
    }

    console.log("Unselected Applications: ", this.unselectedApplicationArray);
    console.log(
      "Action Selected Applications: ",
      this.actionSelectedApplicationArray
    );

    const projectInput = this.approvalForm.get("projectName").value;
    const projectName = projectInput ? projectInput.split("-")[0].trim() : null;

    this.approvalForm.patchValue({
      projectName: projectName,
    });

    if (this.approvalForm.invalid) {
      return;
    }

    this.closeForm();
    this.restService.updatePermission(this.approvalForm.value).subscribe(
      (res) => {
        this.toastr.success(res.message);
        this.onClose(true);
        // window.top.location.reload();
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  handleStatusChange() {
    this.applicationsFetched = true;
    const projectInput = this.approvalForm.get("projectName").value;
    const projectName = projectInput ? projectInput.split("-")[0].trim() : null;

    let applicationNameValue = this.approvalForm.get("applicationName").value;
    if (!Array.isArray(applicationNameValue)) {
      applicationNameValue = applicationNameValue ? [applicationNameValue] : [];
    }

    this.approvalForm.patchValue({
      projectName: projectName,
      applicationName: applicationNameValue,
    });

    let message =
      "Inactivating will revoke " +
      this.approvalForm.value.name +
      "'s access. Do you wish to continue?";

    this.openDialog("Confirmation", message);
  }

  request() {
    this.applicationsFetched =
      this.approvalForm.value.role === "Super Admin"
        ? true
        : this.applicationsFetched;

    if (this.approvalForm.dirty && this.approvalForm.get("role").dirty) {
      this.applicationsFetched = true;
    }

    if (!this.validateForm()) {
      return;
    }

    const projectInput = this.approvalForm.get("projectName").value;
    let projectName = projectInput
      ? projectInput.split("-")[0].trim()
      : this.oldProjectName;

    if (!projectName && this.approvalForm.value.role !== "Super Admin") {
      projectName = this.oldProjectName;
    }

    const applicationArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;

    if (!applicationArray) {
      console.error("applicationName FormArray is not defined.");
      return false;
    }

    const anyApplicationTouched = applicationArray.controls.some(
      (control) => control.touched || control.dirty
    );

    if (
      !this.applicationsFetched &&
      this.approvalForm.value.role !== "Super Admin" &&
      !anyApplicationTouched
    ) {
      this.toastr.warning("Please fetch applications before submitting.");
      return false;
    }

    console.log(
      "actionSelectedApplicationArray:",
      this.actionSelectedApplicationArray
    );
    console.log("unselectedApplicationArray:", this.unselectedApplicationArray);

    const apiData = {
      ...this.approvalForm.value,
      projectName:
        this.approvalForm.value.role === "Super Admin" ? "ALL" : projectName,
      applicationName: [],
      actionSelected: this.actionSelectedApplicationArray,
      applicationUnselected: this.unselectedApplicationArray,
    };

    if (this.approvalForm.value.role === "Super Admin") {
      apiData.applicationName = ["ALL"];
    } else if (this.actionSelectedApplicationArray.length > 0) {
      apiData.applicationName = this.actionSelectedApplicationArray;
    } else {
      const checkedApplications = applicationArray.controls
        .filter((control) => control.value.checked)
        .map((control) => control.value.name);

      apiData.applicationName = checkedApplications.length
        ? checkedApplications
        : [];
    }

    const currentProject = this.approvalForm.get("projectName").value;
    const currentApplications = this.approvalForm.get("applicationName").value;
    console.log("currentApplications", currentApplications);

    if (apiData.applicationName.length === 0) {
      apiData.applicationName = currentApplications;
    }

    this.closeForm();
    this.restService.updatePermission(apiData).subscribe(
      (res) => {
        let msg = res.message;

        if (this.approvalForm.value.status === "INACTIVE") {
          msg = "Rejected successfully!";

          this.approvalForm.patchValue({
            projectName: currentProject,
            applicationName: currentApplications,
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          this.approvalForm.patchValue({
            projectName: currentProject,
            applicationName: currentApplications,
          });
        }

        this.syncApplications();
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  syncApplications() {
    this.toastr.info(
      "Application Sync and Approval started in the background..."
    );

    setTimeout(() => {
      this.spinner.show(); // Hide the spinner after 3 seconds
      this.closeForm(); // Optionally close the form
    }, 5000); // Show the spinner immediately

    this.restApi.syncAllApp().subscribe(
      (data) => {
        this.toastr.success(
          "Approval Successful! Permissions are successfully updated and synced to the downstream application."
        );

        // Set a fixed timeout to simulate loading and hide the spinner after a delay (e.g., 3 seconds)
        setTimeout(() => {
          this.spinner.hide(); // Hide the spinner after 3 seconds
          this.closeForm(); // Optionally close the form
        }, 3000); // Spinner will remain for 3 seconds before hiding
      },
      (error) => {
        // Handle error with Toastr and log the error
        if (error != null && error.error != null) {
          this.toastr.error(error.error.error, "HTTP Status: " + error.status);
        } else {
          this.toastr.error(
            "An error occurred",
            "HTTP Status: " + error.status
          );
        }
        console.error(JSON.stringify(error));

        this.spinner.hide(); // Ensure spinner hides even on error
      }
    );
  }

  onSubmit() {
    if (!this.isEditMode) {
      this.applicationsFetched = true;
    }

    this.applicationsFetched =
      this.approvalForm.value.role === "Super Admin"
        ? true
        : this.applicationsFetched;

    if (!this.validateForm()) {
      return;
    }

    let projectInput = this.approvalForm.get("projectName").value;
    let projectName = projectInput ? projectInput.split("-")[0].trim() : null;

    if (
      !projectName &&
      this.approvalForm.value.role !== "Super Admin" &&
      this.oldProjectName
    ) {
      projectName = this.oldProjectName;
    }

    if (this.approvalForm.value.role === "Super Admin") {
      this.approvalForm.patchValue({
        projectName: "ALL",
        applicationName: ["ALL"],
      });
    } else {
      this.approvalForm.patchValue({
        projectName: projectName,
        applicationName: this.approvalForm.value.applicationName,
      });
    }

    if (this.approvalForm.dirty && this.approvalForm.get("role").dirty) {
      this.applicationsFetched = true;
    }

    const applicationArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;

    // Check if applicationArray is defined
    if (!applicationArray) {
      console.error("applicationName FormArray is not defined.");
      return false; // Prevent further execution
    }

    // Check if any application checkboxes are touched
    const anyApplicationTouched = applicationArray.controls.some(
      (control) => control.touched || control.dirty
    );

    // Ensure applications have been fetched for non-super admin roles
    if (
      !this.applicationsFetched &&
      this.approvalForm.value.role !== "Super Admin" &&
      !anyApplicationTouched // Only show warning if no checkboxes are touched
    ) {
      this.toastr.warning("Please fetch applications before submitting.");
      return false; // Ensure you return false to stop the submission
    }
  }

  closeForm() {
    this.onClose(true);
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }
  onReject() {
    this.isRejectClicked = true;
    this.applicationsFetched = true;
    let projectInput = this.approvalForm.get("projectName").value;
    let projectName = projectInput ? projectInput.split("-")[0].trim() : null;

    if (!projectName && this.oldProjectName) {
      projectName = this.oldProjectName;
    }
    const updatedRole = this.approvalForm.get("role").value;
    this.approvalForm.get("role").setValue(updatedRole);

    let applicationNameValue = this.approvalForm.get("applicationName").value;
    if (!Array.isArray(applicationNameValue)) {
      applicationNameValue = applicationNameValue ? [applicationNameValue] : [];
    }
    // const currentApplications = this.approvalForm.get("applicationName").value;

    // this.approvalForm.patchValue({
    //   projectName: projectName,
    //   applicationName: currentApplications,
    // });
    if (this.approvalForm.value.role === "Super Admin") {
      this.approvalForm.patchValue({
        projectName: "ALL",
        applicationName: ["ALL"],
      });
    } else {
      this.approvalForm.patchValue({
        projectName: projectName,
        applicationName: this.data.userData.project.detail.applicationName,
      });
    }
    this.approvalForm.value.approved = "false";

    if (this.approvalForm.invalid) {
      return;
    }

    this.applicationsFetched = true;
    this.restService.updatePermission(this.approvalForm.value).subscribe(
      (res) => {
        let msg = res.message;
        {
          if (
            res.message ===
            "Approval Successful! Permissions are successfully updated"
          ) {
            msg = "Rejected successfully! ";
          }
          this.applicationsFetched = true;
          this.toastr.success(msg);
          this.onClose(true);
        }

        this.toastr.success(res.message);
        this.onClose(true);
        window.top.location.reload();
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }
}
