import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry } from "rxjs/operators";
import { ApplicationData } from "../manage-applications/manage-applications.component";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class RestService {
  constructor(private http: HttpClient, public authService: AuthService) {
    this.baseURL = environment.baseURL;
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  public baseURL = "";

  getAllProjectDetails(): Observable<any> {
    return this.http.get(this.baseURL + "/projects", this.httpOptions);
  }

  getUserProjects(): Observable<any> {
    return this.http.get(
      this.baseURL + "/projects/getUserProjects",
      this.httpOptions
    );
  }

  getProjectUserApps(projectName): Observable<any> {
    return this.http.get(
      this.baseURL + "/projects/projectUserApplications/" + projectName,
      this.httpOptions
    );
  }

  getAllIcmmsProjectDetails(): Observable<any> {
    return this.http.get(
      this.baseURL + "/projects/icmmsProjects",
      this.httpOptions
    );
  }

  getUserDetails(): Observable<any> {
    return this.http.get(this.baseURL + "/login", this.httpOptions);
  }

  updatePermission(approvalForm): Observable<any> {
    console.log(approvalForm);
    return this.http.post(
      this.baseURL + "/updatePermission",
      approvalForm,
      this.httpOptions
    );
  }

  createUser(approvalForm): Observable<any> {
    console.log(approvalForm);
    return this.http.post(
      this.baseURL + "/users/createUser",
      approvalForm,
      this.httpOptions
    );
  }

  updateProject(projectForm): Observable<any> {
    return this.http.put(
      this.baseURL + "/projects",
      projectForm,
      this.httpOptions
    );
  }

  createProject(projectForm): Observable<any> {
    return this.http.post(
      this.baseURL + "/projects",
      projectForm,
      this.httpOptions
    );
  }

  getProjectsByApplication(id): Observable<any> {
    return this.http.get(
      this.baseURL + "/applications/" + id + "/projects",
      this.httpOptions
    );
  }

  getActiveApplications(): Observable<any> {
    return this.http.get(
      this.baseURL + "/applications/status",
      this.httpOptions
    );
  }

  getStatusValues(): Observable<any> {
    return this.http.get(this.baseURL + "/application", this.httpOptions);
  }

  approvePermission(approveForm): Observable<any> {
    return this.http.post(
      this.baseURL + "/approvePermission",
      approveForm,
      this.httpOptions
    );
  }

  getApplications(): Observable<any> {
    return this.http.get<ApplicationData[]>(
      this.baseURL + "/applications",
      this.httpOptions
    );
  }

  createApplication(applicationDetailForm): Observable<any> {
    return this.http.post<any>(
      this.baseURL + "/applications/",
      applicationDetailForm,
      this.httpOptions
    );
  }

  editApplicationDetail(applicationDetailForm): Observable<any> {
    return this.http.put<any>(
      this.baseURL + "/applications/",
      applicationDetailForm,
      this.httpOptions
    );
  }

  createRole(roleForm): Observable<any> {
    return this.http.post<any>(
      this.baseURL + "/roles",
      roleForm,
      this.httpOptions
    );
  }

  getRoles(): Observable<any> {
    return this.http.get<any[]>(this.baseURL + "/roles", this.httpOptions);
  }

  getActiveRoles(): Observable<any> {
    return this.http.get<any[]>(
      this.baseURL + "/roles/activeRoles",
      this.httpOptions
    );
  }

  getProject(projectId): Observable<any> {
    return this.http.get<any>(
      this.baseURL + "/project/" + projectId,
      this.httpOptions
    );
  }

  getUsers(): Observable<any> {
    return this.http.get<any[]>(this.baseURL + "/users/", this.httpOptions);
  }

  getActiveUsers(id): Observable<any> {
    return this.http.get<any[]>(
      this.baseURL + "/users/active/" + id,
      this.httpOptions
    );
  }

  signUp(signUpForm): Observable<any> {
    return this.http.post<any>(
      this.baseURL + "/signup",
      signUpForm,
      this.httpOptions
    );
  }

  getOnboardedHistory(): Observable<any> {
    return this.http.get<any[]>(
      this.baseURL + "/onboardingHistory/",
      this.httpOptions
    );
  }

  getApprovalRequests(): Observable<any> {
    return this.http.get<any[]>(
      this.baseURL + "/users/approvals/",
      this.httpOptions
    );
  }

  /* getTermsOfUse(): Observable<any> {
    return this.http.get(this.baseURL + '/login/termsOfUse', this.httpOptions);
  } */

  approveTerms(isAgree: boolean): Observable<any> {
    return this.http.post(this.baseURL + "/login/" + isAgree, this.httpOptions);
  }

  // For Dashboard
  getProjectsApps(): Observable<any> {
    return this.http.get(
      this.baseURL + "/dashboard/projectsApplications",
      this.httpOptions
    );
  }

  getRoleForUser(): Observable<any> {
    return this.http.get(
      this.baseURL + "/users/isRolePresent",
      this.httpOptions
    );
  }

  syncAllApp(): Observable<any> {
    console.log("URL", this.baseURL);
    return this.http.get(this.baseURL + "/projects/sync", this.httpOptions);
  }

  getUserRoleInIcmmsLop(projectCode): Observable<any> {
    return this.http.get(
      this.baseURL + "/projects/lopicmmsrole?projectCode=" + projectCode,
      this.httpOptions
    );
  }

  getLanguageOptions(): Observable<any> {
    return this.http.get(
      this.baseURL + "/projects/languages",
      this.httpOptions
    );
  }

  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      console.warn("${error.message}");
      errorMessage = `Error Message: ${error.error.message}`;
    }
    return throwError(errorMessage);
  }
}
